import React, { ReactElement, useEffect } from "react";

interface Props {}

export function TesteRedirect(props: Props): ReactElement {
  useEffect(() => {
    console.log("teste");
    window.location.href =
      "https://apps.apple.com/br/app/seven-7-minute-workout/id650276551?l=en";
    return () => {};
  }, []);
  return <div></div>;
}
