import { createMuiTheme, Color, PaletteType } from "@material-ui/core";
import { colors } from "@material-ui/core";
import { TypeBackground } from "@material-ui/core/styles/createPalette";

const primary = colors.blueGrey;
const secondary = colors.lightBlue;

export function getContrastText(background: string) {
  const theme = createMuiTheme();
  return theme.palette.getContrastText(background);
}

export function defaultThemeWithColors(props: {
  type?: PaletteType;
  primary: Color;
  secondary?: Partial<Color>;
  background?: Partial<TypeBackground>;
}) {
  props.type = props.type || "light";
  props.primary = props.primary || primary;
  props.secondary = props.secondary || secondary;
  props.background = props.background || {
    default: colors.blueGrey[900],
  };

  return createMuiTheme({
    palette: {
      type: props.type,
      primary: props.primary,
      secondary: props.secondary,
      background: props.background,
    },
    shape: {
      borderRadius: 1,
    },
    overrides: {
      MuiButton: {
        root: {
          minWidth: 100,
          minHeight: 50,
        },
      },
      MuiFab: {
        primary: {
          backgroundColor: colors.blueGrey[900],
        },
      },
    },
  });
}

export const defaultTheme = defaultThemeWithColors({
  primary,
  secondary,
});
