import * as React from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import { LangingPage } from "../pages/landing/landing.page";
import { TesteRedirect } from "../pages/teste/teste";

interface AppRoute {
  path: string;
  name?: string;
  component: React.ReactNode;
  authorization?: () => boolean;
  fallback?: string;
  exact?: boolean;
}

function route(props: AppRoute) {
  return props;
}

export const appRoutes = {
  landingPage: route({
    exact: true,
    path: "/",
    name: "Landing",
    component: <LangingPage />,
  }),
  testePage: route({
    path: "/teste",
    name: "teste",
    component: <TesteRedirect />,
  }),
};

export const appRoutesArray = Object.values(appRoutes);

interface IAppRouterProps {}

const AppRouter: React.FunctionComponent<IAppRouterProps> = (props) => {
  return (
    <Router>
      <Switch>
        {appRoutesArray.map((route, idx) => {
          if (route.authorization) {
            return (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                render={() =>
                  route.authorization && route.authorization() ? (
                    route.component
                  ) : (
                    <Redirect to={route.fallback || "/"} />
                  )
                }
              />
            );
          }
          return (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              render={() => route.component}
            />
          );
        })}
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
