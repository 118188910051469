import React, { ReactElement } from "react";
import {
  Box,
  ButtonBase,
  Fab,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { HeaderComponent } from "../../components";
import AppImage from "../../assets/images/app.png";
import GooglePlayIcon from "../../assets/images/google-play.svg";
import { ReactSVG } from "react-svg";
import Image from "material-ui-image";

const useStyles = makeStyles((theme) => ({
  img: {
    verticalAlign: "middle",
    width: "100%",
  },
  appImage: {
    background: "transparent",
  },
  fabBox: {
    position: "fixed",
    bottom: 0,
    padding: theme.spacing(2),
    width: "100%",
    display: "flex",
  },
  fab: {
    width: "100%",
  },
}));

interface Props {}

export function LangingPage(props: Props): ReactElement {
  const classes = useStyles(props);

  const handleDownload = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.getblumeter.droid";
  };

  return (
    <>
      <HeaderComponent />
      <Box display="flex" height="100%" id="background">
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              flex={1}
              height="100%"
              alignItems="flex-end"
              justifyContent="center"
              p={8}
              textAlign="right"
              flexDirection="column"
            >
              <Typography variant="h3">
                Manage your private trips as a professional
              </Typography>
              <Hidden smDown>
                <ButtonBase focusRipple onClick={handleDownload}>
                  <ReactSVG src={GooglePlayIcon} />
                </ButtonBase>
              </Hidden>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              flex={1}
              height="100%"
              alignItems="center"
              justifyContent="center"
              p={8}
            >
              <Box width={{ xs: "80%", md: "100%", lg: "50%" }}>
                <Image
                  color="transparent"
                  aspectRatio={9 / 18}
                  src={AppImage}
                  alt="app"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Hidden mdUp>
        <Box className={classes.fabBox}>
          <Fab
            variant="extended"
            color="primary"
            className={classes.fab}
            onClick={handleDownload}
          >
            DOWNLOAD
          </Fab>
        </Box>
      </Hidden>
    </>
  );
}
