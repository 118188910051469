import React, { ReactElement } from "react";
import { AppBar, makeStyles, Toolbar, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  appBar: {
    display: "flex",
    alignItems: "center",
    flexFlow: "row nowrap",
  },
  container: {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    width: "100%",
    "@media (min-width: 576px)": {
      maxWidth: "540px",
    },
    "@media (min-width: 768px)": {
      maxWidth: "720px",
    },
    "@media (min-width: 992px)": {
      maxWidth: "960px",
    },
    "@media (min-width: 1200px)": {
      maxWidth: "1140px",
    },
    minHeight: "50px",
    flex: "1",
    alignItems: "center",
    display: "flex",
    flexWrap: "nowrap",
  },
  logo: {
    height: 30,
    width: 30,
    padding: 4,
  },
});

interface Props {}

export function HeaderComponent(props: Props): ReactElement {
  const classes = useStyles();
  return (
    <AppBar color="transparent" elevation={0} className={classes.appBar}>
      <Toolbar className={classes.container}>
        <img className={classes.logo} src="/logo192.png" alt="Blumeter" />
        <Typography variant="h6">Blumeter</Typography>
      </Toolbar>
    </AppBar>
  );
}
